<template>
    <div>
        <h1>Invitation</h1>
        <span v-if="!logged">You must be logged to take an invitation.</span>
        <div v-else>
            <span v-if="!invitation">The invitation {{ $route.params.invitationId}} does not exists.</span>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'

    import firebase from '../firebase'
    import 'firebase/compat/firestore';

    const firestore = firebase.firestore();

    export default {
        name: 'COInvitation',
        data() {
            return {
                invitation: null
            }
        },
        computed: mapState({
            logged: state => state.user.logged,
            user: state => state.user.user,
        }),
        methods: {
            openInvitation() {
                firestore.collection('invitations').doc(this.$route.params.invitationId)
                    .get()
                    .then(doc => {
                        if (doc.exists) {
                            this.invitation = doc.data()
                        }
                    })
            }
        },
        watch: {
            logged() {
                this.openInvitation()
            },
            invitation() {
                firestore.collection('events').doc(this.invitation.eventId)
                    .collection('participants').doc(this.invitation.participantId)
                    .get()
                    .then(doc => {
                        const participant = doc.data();
                        participant.invitationId = this.$route.params.invitationId;
                        firestore.collection('events').doc(this.invitation.eventId)
                            .collection('participants').doc(this.user.uid)
                            .set(participant)
                            .then(() => {
                                firestore.collection('events').doc(this.invitation.eventId)
                                    .collection('participants').doc(this.invitation.participantId)
                                    .delete()
                                    .then(() => {
                                        firestore.collection('invitations').doc(this.$route.params.invitationId)
                                            .delete()
                                            .then(() => {
                                                firestore.collection('events').doc(this.invitation.eventId)
                                                    .collection('participants').doc(this.user.uid)
                                                    .update({
                                                        'logged': true,
                                                        'invitationId': firebase.firestore.FieldValue.delete()
                                                    })
                                                    .then(() => {
                                                        this.$router.push('/events/' + this.invitation.eventId)
                                                    })
                                            })
                                    })
                            })
                    })
            }
        },
        created() {
            if (this.logged) {
                this.openInvitation()
            }
        }
    }
</script>
