import firebase from 'firebase/compat/app';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: "christmas-organizer.firebaseapp.com",
  databaseURL: "https://christmas-organizer.firebaseio.com",
  projectId: "christmas-organizer",
  storageBucket: "christmas-organizer.appspot.com",
  messagingSenderId: "455277370019",
  appId: process.env.VUE_APP_APP_ID,
  measurementId: "G-PKBMLZ5XTC"
};
const firebaseApp = firebase.initializeApp(firebaseConfig);

export default firebase
export { firebaseApp }
