import { createRouter, createWebHashHistory } from 'vue-router'

import Event from './views/Event'
import EventList from './views/EventList'
import Home from './views/Home'
import Invitation from './views/Invitation'

const routes = [
    {path: '/', redirect: '/home'},
    {path: '/home', component: Home},
    {path: '/events', component: EventList},
    {path: '/events/:eventId', component: Event},
    {path: '/invitations/:invitationId', component: Invitation}
];

export default createRouter({
    history: createWebHashHistory(),
    routes
})