<template>
    <div>
        <v-btn v-if="!user.uid" v-on:click="login" text>
            <span>Connexion</span>
        </v-btn>
        <v-menu v-else
                :close-on-content-click="true"
                offset-y
        >
            <template v-slot:activator="{ props }">
                <v-btn v-bind="props" icon>
                    <v-avatar>
                        <v-img :src="user.photoURL" :alt="user.displayName"></v-img>
                    </v-avatar>
                </v-btn>
            </template>
            <v-card>
                <v-list>
                    <v-list-item
                        :prepend-avatar="user.photoURL"
                        :title="user.displayName"
                        :subtitle="user.email"
                    ></v-list-item>
                </v-list>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-on:click="logout" text>Sign Out</v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";

    export default {
        name: "COLogin",
        data: function () {
            return {
            }
        },
        computed: mapState({
            user: state => state.user,
        }),
        methods: {
            logout() {
                if (this.$router.currentRoute.path !== '/home') {
                    this.$router.replace('/home');
                }
                this.$store.dispatch('logout')
            },
            ...mapActions([
                'login',
            ])
        }
    }
</script>
