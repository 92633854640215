<template>
    <nav>
        <v-app-bar app color="primary" dark>
            <v-app-bar-nav-icon v-on:click="drawer = !drawer"/>
            <v-toolbar-title>Christmas Organizer</v-toolbar-title>
            <v-spacer/>
            <login/>
            <v-spacer/>
            <settings/>
        </v-app-bar>

        <v-navigation-drawer app v-model="drawer">
            <v-list>
                <v-list-item v-on:click="goTo('/home')">
                    <template v-slot:prepend>
                        <v-icon>mdi-home</v-icon>
                    </template>
                    <v-list-item-title>Accueil</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="$store.state.user.displayName" v-on:click="goTo('/events')">
                    <template v-slot:prepend>
                        <v-icon>mdi-calendar-text</v-icon>
                    </template>
                    <v-list-item-title>Événements</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </nav>
</template>

<script>
    import Login from "./Login";
    import Settings from "./Settings";

    export default {
        name: "CONavigation",
        components: {
            Login,
            Settings
        },
        data: function () {
            return {
                drawer: false
            }
        },
        methods: {
            goTo(path) {
                if (this.$router.currentRoute.path !== path) {
                    this.$router.push(path)
                }
            }
        }
    }
</script>

<style scoped>

</style>
