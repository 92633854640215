import { createApp } from 'vue'
import App from './App.vue'

import { firebaseApp } from './firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

const auth = getAuth(firebaseApp);
onAuthStateChanged(auth, user => {
  if (user) {
    store.dispatch('log', user)
  }
});

createApp(App).use(router).use(store).use(vuetify).mount('#app');
