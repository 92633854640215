function getPossibleParticipantIndexes(numberOfParticipants) {
    const possibleParticipantIndexes = [];
    for (let i = 0; i < numberOfParticipants; i++) {
        possibleParticipantIndexes.push(i)
    }
    return possibleParticipantIndexes
}

function shuffled(participantIndexes) {
    return participantIndexes.find((participantIndex, index) => participantIndex === index) === undefined
}

function swap(participantIndexes, index1, index2) {
    const temp = participantIndexes[index1];
    participantIndexes[index1] = participantIndexes[index2];
    participantIndexes[index2] = temp
}

export default {
    shuffle(numberOfParticipants) {
        if (numberOfParticipants <= 0) {
            return []
        } else if (numberOfParticipants === 1) {
            return [0]
        }

        const participantIndexes = getPossibleParticipantIndexes(numberOfParticipants);
        while (!shuffled(participantIndexes)) {
            let randomIndex1 = Math.floor(Math.random() * participantIndexes.length);
            let randomIndex2 = Math.floor(Math.random() * participantIndexes.length);
            swap(participantIndexes, randomIndex1, randomIndex2)
        }
        return participantIndexes
    }
}