import firebase, { firebaseApp } from '../firebase'
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const auth = getAuth(firebaseApp);
const firestore = firebase.firestore();

export default {
    state: {
        uid: '',
        displayName: '',
        photoURL: '',
        email: '',
        eventList: [],
        userListener: null
    },
    getters: {
        getEvent(state) {
            return (eventId) => {
                return state.eventList.find(event => event.id === eventId)
            }
        }
    },
    mutations: {
        loadUser(state, user) {
            state.uid = user.uid;
            state.displayName = user.displayName;
            state.photoURL = user.photoURL;
            state.email = user.email;
            state.eventList = user.eventList
        },
        setUserListener(state, userListener) {
            state.userListener = userListener
        },
        logout(state) {
            state.userListener();
            state.userListener = null;
            state.uid = '';
            state.displayName = '';
            state.photoURL = '';
            state.email = '';
            state.userListener = []
        }
    },
    actions: {
        login({dispatch}) {
            const provider = new GoogleAuthProvider();
            signInWithPopup(auth, provider).then(result => {
                dispatch('log', result.user);
            })
        },
        log({dispatch}, user) {
            firestore.collection('users').doc(user.uid)
                .get()
                .then(doc => {
                    if (!doc.exists) {
                        firestore.collection('users').doc(user.uid)
                            .set({
                                displayName: user.displayName,
                                photoURL: user.photoURL,
                                email: user.email,
                                eventList: []
                            })
                            .then(dispatch('loadUser', user.uid))
                    } else {
                        dispatch('loadUser', user.uid)
                    }
                })
        },
        loadUser({commit}, userUid) {
            const userListener = firestore.collection('users').doc(userUid)
                .onSnapshot(doc => {
                    const user = doc.data();
                    commit('loadUser', {
                        uid: userUid,
                        displayName: user.displayName,
                        photoURL: user.photoURL,
                        email: user.email,
                        eventList: user.eventList
                    });
                });
            commit('setUserListener', userListener)
        },
        logout({commit}) {
            commit('logout');
        },
        addEvent({state}, event) {
            firestore.collection('users').doc(state.uid)
                .update({eventList: firebase.firestore.FieldValue.arrayUnion(event)})
        },
        updateEvent({dispatch}, event) {
            dispatch('deleteEvent', event.id);
            dispatch('addEvent', event)
        },
        deleteEvent({getters, state}, eventId) {
            const event = getters.getEvent(eventId);
            firestore.collection('users').doc(state.uid)
                .update({
                    eventList: firebase.firestore.FieldValue.arrayRemove(event)
                })
        }
    }
}
