<template>
    <v-dialog v-if="participants" v-bind:value="show" persistent>
        <v-card>
            <v-card-title>Vous êtes ?</v-card-title>
            <v-card-text>
                <v-list>
                    <v-list-item v-for="participant in participants" v-bind:key="participant[0]"
                                 v-on:click="selectParticipant(participant[0])"
                    >
                        {{ participant[1].name }}
                    </v-list-item>
                    <v-list-item>
                        <v-text-field v-model="newParticipant" placeholder="Nouveau participant"/>
                        <v-icon v-on:click="addParticipant">mdi-account-plus</v-icon>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <v-btn v-on:click="$router.replace('/events')">Annuler</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import firebase from "../firebase";
    import 'firebase/compat/firestore';

    const db = firebase.firestore();

    export default {
        name: "EventParticipantJoin",
        props: ['participants'],
        data() {
            return {
                newParticipant: ''
            }
        },
        computed: {
            show() {
                return !Array.from(this.participants)
                    .find(participant => {
                        return participant[1].uid === this.$store.state.user.uid
                    })
            }
        },
        methods: {
            selectParticipant(participandId) {
                db.collection('events').doc(this.$route.params.eventId)
                    .collection('participants').doc(participandId)
                    .update({'uid': this.$store.state.user.uid})
            },
            addParticipant() {
                this.$emit('addParticipant', {
                    name: this.newParticipant,
                    receiver: '',
                    uid: this.$store.state.user.uid
                })
            }
        }
    }
</script>
