<template>
    <div>
        <h2>Bienvenue sur Christmas Organizer !</h2>
        <p v-if="!$store.state.user.uid">Vous devez vous connecter pour créer ou participer aux événements.</p>
        <p v-else>Allez à la page événements en faisant apparaître le menu de l'applicatin à gauche.</p>
    </div>
</template>

<script>
    export default {
        name: 'COHome'
    }
</script>