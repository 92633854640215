<template>
    <div v-if="event">
        <h1>{{ event.name }}</h1>
        <p v-if="!isUserLogged">
            <v-icon>mdi-account-alert</v-icon>
            Vous devez être connecté pour participer au secret santa !
        </p>
        <event-participant-join v-if="isUserLogged"
                                v-bind:participants="participants"
                                v-on:addParticipant="addParticipant"
        />
        <v-btn v-if="isUserAdmin" v-on:click="assignReceivers($route.params.eventId)">Secret Santa</v-btn>
        <v-table>
            <template v-slot:default>
                <thead>
                <tr>
                    <th>Participants</th>
                    <th>Receveurs</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="participant in participants" :key="participant[0]">
                    <td>{{ participant[1].name }}</td>
                    <td v-if="isUser(participant[1])">{{ participant[1].receiver }}</td>
                    <td v-else-if="isUserAdmin">
                        <v-btn v-on:click="revealReceiver(participant[0])" text>
                            <v-icon>
                                {{ showReceiver(participant[0]) ? 'mdi-eye' : 'mdi-eye-off' }}
                            </v-icon>
                        </v-btn>
                        <span v-if="showReceiver(participant[0])">
                            {{ participant[1].receiver }}
                        </span>
                    </td>
                    <td v-else>
                        <v-icon>mdi-account-lock</v-icon>
                    </td>
                    <td>
                        <v-icon v-on:click="removeParticipant(participant[0])">mdi-account-minus</v-icon>
                    </td>
                </tr>
                <tr>
                    <td>
                        <v-text-field v-model="newParticipant.name"></v-text-field>
                    </td>
                    <td>
                        <v-text-field v-model="newParticipant.receiver"></v-text-field>
                    </td>
                    <td>
                        <v-icon v-bind:disabled="newParticipant.name.trim() === ''"
                                v-on:click="addParticipant(newParticipant)"
                        >
                            mdi-account-plus
                        </v-icon>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-table>
    </div>
</template>

<script>
    import { firebaseApp } from "../firebase";
    import { addDoc, collection, deleteDoc, doc, getFirestore, onSnapshot, updateDoc } from 'firebase/firestore';

    import secretSanta from "../core/secretSanta";

    import EventParticipantJoin from "../components/EventParticipantJoin";

    const db = getFirestore(firebaseApp);

    export default {
        name: 'COEvent',
        components: {
            EventParticipantJoin
        },
        data() {
            return {
                event: null,
                eventListener: null,
                participants: null,
                participantsListener: null,
                newParticipant: {
                    name: '',
                    receiver: ''
                },
                showReceivers: new Set()
            }
        },
        computed: {
            isUserLogged() {
                return this.$store.state.user.uid
            },
            isUserAdmin() {
                return this.$store.state.user.uid === this.event.adminId
            }
        },
        methods: {
            addParticipant(newParticipant) {
                addDoc(collection(db, 'events', this.$route.params.eventId, 'participants'), newParticipant)
                    .then(() => {
                        this.newParticipant = {name: '', receiver: ''}
                    })
            },
            removeParticipant(participantId) {
                deleteDoc(doc(db, 'events', this.$route.params.eventId, 'participants', participantId))
            },
            assignReceivers() {
                const shuffledParticipantsIndexes = secretSanta.shuffle(this.participants.size);
                const participantsArray = Array.from(this.participants)
                participantsArray.forEach((participant, index) => {
                    updateDoc(doc(db, 'events', this.$route.params.eventId, 'participants', participant[0]), {
                        'receiver': participantsArray[shuffledParticipantsIndexes[index]][1].name
                    })
                })
            },
            isUser(participant) {
                return participant.uid === this.$store.state.user.uid
            },
            revealReceiver(participantId) {
                if (this.showReceivers.has(participantId)) {
                    this.showReceivers = new Set(this.showReceivers);
                    this.showReceivers.delete(participantId)
                } else {
                    this.showReceivers = new Set(this.showReceivers);
                    this.showReceivers.add(participantId)
                }
            },
            showReceiver(participantId) {
                return this.showReceivers.has(participantId)
            }
        },
        watch: {
            isUserLogged(newLogged) {
                if (newLogged) {
                    this.eventListener();
                    this.eventListener = onSnapshot(doc(db, 'events', this.$route.params.eventId), doc => {
                        this.event = doc.data();
                        const event = this.$store.getters.getEvent(this.$route.params.eventId);
                        if (!event) {
                            this.$store.dispatch('addEvent', {
                                id: this.$route.params.eventId,
                                name: this.event.name
                            })
                        } else if (event.name !== this.event.name) {
                            this.$store.dispatch('updateEvent', {
                                id: this.$route.params.eventId,
                                name: this.event.name
                            })
                        }
                    });
                }
            }
        },
        created() {
            this.eventListener = onSnapshot(doc(db, 'events', this.$route.params.eventId), doc => {
                this.event = doc.data();
                if (this.isUserLogged) {
                    const event = this.$store.getters.getEvent(this.$route.params.eventId);
                    if (!event) {
                        this.$store.dispatch('addEvent', {
                            id: this.$route.params.eventId,
                            name: this.event.name
                        })
                    } else if (event.name !== this.event.name) {
                        this.$store.dispatch('updateEvent', {
                            id: this.$route.params.eventId,
                            name: this.event.name
                        })
                    }
                }
            });
            this.participantsListener = onSnapshot(collection(db, 'events', this.$route.params.eventId, 'participants'), docs => {
                const participants = new Map();
                docs.forEach(doc => participants.set(doc.id, doc.data()));
                this.participants = participants
            })
        },
        destroyed() {
            this.eventListener();
            this.participantsListener()
        }
    }
</script>
