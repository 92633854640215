<template>
    <v-menu :close-on-content-click="true">
        <template v-slot:activator="{ props }">
            <v-btn v-bind="props" icon>
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item v-if="$route.params.eventId && canShare()">
                <v-icon>mdi-share</v-icon>
                <span v-on:click="shareEvent">Partager l'événement</span>
            </v-list-item>
            <v-list-item v-if="$route.params.eventId" class="red--text">
                <v-icon>mdi-delete</v-icon>
                <span class="red--text" v-on:click="deleteEvent($route.params.eventId)">Supprimer l'événement</span>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    import { doc, deleteDoc, getFirestore } from 'firebase/firestore';
    import { firebaseApp } from '../firebase';

    const db = getFirestore(firebaseApp);

    export default {
        name: "COSettings",
        data() {
            return {
            }
        },
        methods: {
            canShare() {
                return navigator.share
            },
            shareEvent() {
                navigator.share({
                    title: 'Partager l\'événement',
                    text: 'Venez rejoindre l\'événement:',
                    url: process.env.VUE_APP_BASE_URL + '/#' + this.$route.path,
                })
            },
            deleteEvent(eventId) {
                deleteDoc(doc(db, 'events', eventId))
                    .then(() => this.$store.dispatch('deleteEvent', eventId));
                this.$router.back()
            }
        }
    }
</script>
