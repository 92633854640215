<template>
    <v-dialog v-model="show">
        <v-card>
            <v-card-title>Créer un événement</v-card-title>
            <v-text-field v-model="name"
                          placeholder="Nom de l'événement"
            />
            <v-card-actions>
                <v-btn v-on:click="$emit('cancel')">Annuler</v-btn>
                <v-btn color="primary"
                        v-on:click="$emit('createEvent', name)"
                       v-bind:disabled="!name"
                >Créer</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "EventCreator",
        data() {
            return {
                show: true,
                name: ''
            }
        }
    }
</script>
