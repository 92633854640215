<template>
    <div>
        <div>
        <v-btn v-on:click="showEventCreator = true">Créer un événement</v-btn>
        <event-creator v-if="showEventCreator"
                       v-on:createEvent="createEvent"
                       v-on:cancel="showEventCreator = false"
        >
        </event-creator>
        </div>
        <v-table>
            <template v-slot:default>
                <thead>
                <th>Nom</th>
                <th>Actions</th>
                </thead>
                <tbody>
                <tr v-for="event in user.eventList" :key="event.id">
                    <td>{{ event.name }}</td>
                    <td>
                        <v-btn v-on:click="goTo('/events/' + event.id)" color="primary">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-table>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    import { firebaseApp } from "../firebase";
    import { addDoc, arrayUnion, collection, doc, getFirestore, updateDoc } from "firebase/firestore";

    import EventCreator from "../components/EventCreator";

    const db = getFirestore(firebaseApp);

    export default {
        name: "EventList",
        components: {
            EventCreator
        },
        data() {
            return {
                showEventCreator: false
            }
        },
        computed: {
            ...mapState({
                user: state => state.user,
            }),
        },
        methods: {
            goTo(route) {
                this.$router.push(route)
            },
            createEvent(eventName) {
                addDoc(collection(db, 'events'), {
                        adminId: this.user.uid,
                        name: eventName
                })
                .then(newEvent => {
                    updateDoc(doc(db, 'users', this.user.uid), {
                            eventList: arrayUnion({
                                id: newEvent.id,
                                name: eventName
                            })
                    })
                });
                this.showEventCreator = false
            }
        }
    }
</script>
